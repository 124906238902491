import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { EditsourceComponent } from './components/editsource/editsource.component';
import { RssfeedComponent } from './components/rssfeed/rssfeed.component';
import { RssdetailsComponent } from './components/rssdetails/rssdetails.component';
import {AuthGuard}from './guards/auth.guard'
import { ExtractedDataComponent } from './components/extracted-data/extracted-data.component';
import { ImageLabellingComponent } from './components/image-labelling/image-labelling.component';
import { AdminhomeComponent } from './components/adminhome/adminhome.component';
import { CountrydetailsComponent } from './components/countrydetails/countrydetails.component';
import { LanguagedetailsComponent } from './components/languagedetails/languagedetails.component';
import { DatedetailsComponent } from './components/datedetails/datedetails.component';

const routes: Routes = [
  {path:'',redirectTo:'/login',pathMatch:'full',},
  {path: "login",component: LoginComponent ,pathMatch:'full'},
  {path: 'home',component: HomeComponent ,pathMatch:'full',canActivate: [AuthGuard]},
  {path: "editsource/:id",data:{id:'id'},component: EditsourceComponent ,canActivate: [AuthGuard],pathMatch:'full'},
  {path: "rssfeed/:id",data:{id:'id'},component: RssfeedComponent ,canActivate: [AuthGuard],pathMatch:'full'},
  {path: "rssdetails/:id",data:{id:'id'},component: RssdetailsComponent ,canActivate: [AuthGuard],pathMatch:'full'},
  {path: "extracted-data/:id",data:{id:'id'},component: ExtractedDataComponent ,canActivate: [AuthGuard],pathMatch:'full'},
  {path: "image-labelling/:id",data:{id:'id'},component:ImageLabellingComponent ,pathMatch:'full'},

  {path: 'adminhome',component: AdminhomeComponent ,pathMatch:'full',canActivate: [AuthGuard],data:{role:'ROLE_SUPERADMIN'}},
  {path: 'countrydetails',component: CountrydetailsComponent ,pathMatch:'full',canActivate: [AuthGuard],data:{role:'ROLE_SUPERADMIN'}},
  {path: 'languagedetails',component: LanguagedetailsComponent ,pathMatch:'full',canActivate: [AuthGuard],data:{role:'ROLE_SUPERADMIN'}},
  {path: 'datedetails',component: DatedetailsComponent ,pathMatch:'full',canActivate: [AuthGuard],data:{role:'ROLE_SUPERADMIN'}},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
