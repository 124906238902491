import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
@Component({
  selector: 'app-editsource',
  templateUrl: './editsource.component.html',
  styleUrls: ['./editsource.component.css']
})
export class EditsourceComponent implements OnInit {
  faBackspace=faBackspace
  sub: any;
  new: any;
  newdata: any;
  loginForm: FormGroup;
  submitted: boolean;
  loading = false;
  newdatak: [string, any][];
  dataSourceName:any;
  item:any[];
  all:any[];
  selectedItems: Array<any> = [];
  dropdownSettings: any = {};
  dropdownList: Array<any> = [];
  
  newa=[]
  label=['']
  closeResult: string;
  public updateForm: FormGroup= new FormGroup({
    dataSourceName:new FormControl ('', Validators.required),
    dataSourceURL:new FormControl ('', Validators.required),
    countryCode:new FormControl ('', Validators.required),
    languageCode:new FormControl ('', Validators.required),
    websiteTag:new FormControl ([], Validators.required),
    crawlableNormally:new FormControl ('', Validators.required),
    datePattern:new FormControl ('', Validators.required),
    
    searchUrl:new FormControl ('', Validators.required),
    harvestingLinksSelector:new FormControl ('', Validators.required),
    harvestDateSelector:new FormControl ('', Validators.required),
    nextPageSelector:new FormControl ('', Validators.required),
    articleTitleSelector:new FormControl ('', Validators.required),
    articleBodySelector:new FormControl ('', Validators.required),
    articleDateSelector:new FormControl ('', Validators.required),
    articleImageUrlSelector:new FormControl ('', Validators.required),
    newsAuthorSelector:new FormControl ('', Validators.required),
    rssFeedStatus:new FormControl ('', Validators.required),
    sampleArticleUrl:new FormControl ('', Validators.required)
    
    });
  countries: [];
  language: any;
  country: any;
  lang: any;
  date: any;
  websiteTag: any;
  dropdownLis: [];
  
//   public crawlableNormally:boolean;

//   public onChanged(value:boolean){
//     this.crawlableNormally = value;
// }


  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
    
    
    
    ) { }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      
      console.log("runn",params)
      this.new=params['id']
      console.log(this.new);
      this.data.getDataSourceById(this.new).subscribe(
        data=>{
          if(data.statusCode==200){
            let alertObj = {
              message: data.message,
              status: data.infoType,
              autoDismiss: true,
              timeToDismiss: 5000
            };
            this.data.toastrShow(alertObj.message,"success")
            console.log(data);
            this.newdata=data['data']
            this.websiteTag=this.newdata['websiteTag']
            console.log(this.websiteTag)
            this.country=this.newdata.country
            this.lang=this.newdata.language
            console.log(this.country);
            
            console.log(this.newdata)
            this.newdatak=Object.entries(this.newdata)
            console.log(this.newdatak)
          }
          
          
        },
        (err:HttpErrorResponse)=>{
          console.log(err)
          let alertObj = {
            message: err.error.message,
            status: err.error.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      )
      
  });
  this.data.getcountry().subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.countries=data.data
        console.log('upcountrry',this.countries)
      }
    }
  )
  this.data.getlang().subscribe(
    data=>{
      if(data.statusCode==200){
        
        this.language=data.data
        console.log(this.language)
      }
    }
  );
  this.data.getDatePattern().subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.date=data.data
        console.log(this.date)
      }
    }
  );
     this.dropdownList=['World','Business','Politics','Health','Education & Family','Science & Environment','Technology','Entertainment & Arts','Economy','Sports']
  
    

    this.selectedItems = this.websiteTag
  this.dropdownSettings = {
    singleSelection: false,
    
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 300,
    allowSearchFilter: true
  };
  
//  this.updateForm = this.formBuilder.group({
//   dataSourceName: ['', Validators.required],
//   dataSourceURL: ['', Validators.required],
//   countryCode: ['', Validators.required],
//   languageCode: ['', Validators.required],
//   websiteTag: [["World", "Business", "Politics", "Health", "Education & Family", "Science & Environment", "Technology", "Entertainment & Arts"], Validators.required],
//   crawlableNormally: ['', Validators.required],
//   datePattern: ['', Validators.required],
  
//   searchUrl: ['', Validators.required],
//   harvestingLinksSelector: ['', Validators.required],
//   harvestDateSelector: ['', Validators.required],
//   nextPageSelector: ['', Validators.required],
//   articleTitleSelector: ['', Validators.required],
//   articleBodySelector: ['', Validators.required],
//   articleDateSelector: ['', Validators.required],
//   articleImageUrlSelector: ['', Validators.required],
//   newsAuthorSelector: ['', Validators.required],
//   rssFeedStatus: ['', Validators.required],
//   sampleArticleUrl: ['', Validators.required]
  
//   });


}
onItemSelect(item: any) {
  console.log(item);
}
onSelectAll(items: any) {
  console.log(items);
}
 
 get fa() { return this.updateForm.controls; }
  onSubmitnew(){
    // console.log(this.updateForm.value);
    this.submitted = true;
    if(this.updateForm.invalid) {
      return;
      
  }
  this.loading = true;
  console.log(this.updateForm.value);
  this.data.updateDataSource(this.updateForm.value).subscribe(
    data=>{
      if(data.statusCode==200){
        this.loading = false;
        let alertObj = {
          message: data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
        console.log(data);
        this.modalService.dismissAll()
      }
      
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.loading=false
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    
    
  )
  

  
  }
 open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
// closeModal(content) {
//   this.modalService.close(content);
// }

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
logout(){
  this.auth.logout();
}
goback(){
  this.location.back()
}

}
