<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>
        </li>
      </ul>
    
    <!-- Links -->
    <!-- <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#">Link 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 3</a>
      </li>
    </ul> -->
  </nav>
  <span class="float-left">
    <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
  <div class="container">
      <div class="col-lg col-md col-sm">
        <span class="float-right" style="padding: 30px;">
          <button class="btn btn-primary" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Event Details</button>
          <button class="btn btn-primary" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Country Details</button>
          <button class="btn btn-primary" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Language Details</button>
          <button class="btn btn-primary"[routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Date Pattern Service</button>
        </span>
        </div>
  </div>
  <div class="container-fluid ">
    <!-- <div class="d-flex justify-content-center" >
    <input  width="50%" type="text" name="search" autocomplete="off" placeholder="Search">
    </div> -->
  <!-- <span class="float-right">
    <button class="btn btn-info" (click)="open(content)" id="create-btn" >Add New Country</button>
        
  </span> -->
  <div class="d-flex justify-content table-responsive" style="margin-top: 20px; margin-bottom: 20px;">
    <div class="pull-left"><button class="btn btn-warning" (click)="clearFilter()">Clear Filters</button></div>
    <div>
      <input type="text" placeholder="Search By Id" [(ngModel)]="name" style="margin-left: 400px;"></div>
      <div>
        <button (click)="getEventById()" style="background-color: cornflowerblue;" >Search</button>
      
    </div>
  </div>
  <div class="col-12 row">
    <div class="form-group col-3">
      <label for="exampleFormControlSelect1">Select Event Type</label>
      <select class="form-control" [(ngModel)]="eventType" name="eventType" (click)="getEventByFilter()">
        <option value="RSS_FEED_CRAWL">RSS FEED CRAWL</option>
        <option value="RSS_FEED_REPEATED_CRAWL">RSS FEED REPEATED CRAWL</option>
      </select>
    </div>
    <div class="form-group col-3">
      <label for="exampleFormControlSelect1">Select Event Status</label>
      <select class="form-control" [(ngModel)]="eventStatus" name="eventStatus" (click)="getEventByFilter()">
        <option value="CREATED">CREATED</option>
        <option value="IN_SQS">IN SQS</option>
        <option value="FAILED">FAILED</option>
        <option value="COMPLETED">COMPLETED</option>
        <option value="ALREADY_COMPLETED">ALREADY COMPLETED</option>
        <option value="COMPLETED_WITH_PARTIAL_ERROR_IN_ARTICLE_GENERATION">COMPLETED WITH PARTIAL ERROR IN ARTICLE GENERATION</option>
      </select>
    </div>
    <div class="form-group col-3">
      <label>Select Start Date</label>
      <input class="form-control" type="date" [(ngModel)]="startDate" name="startDate"
          placeholder="Enter Title" data-key="enter-title" (click)="getEventByFilter()" >
  </div>
  <div class="form-group col-3">
    <label>Select End Date</label>
    <input class="form-control" type="date" [(ngModel)]="endDate" name="endDate"
        placeholder="Enter Title" data-key="enter-title" (click)="getEventByFilter()">
</div>
  </div>
  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshData($event)">
    <option [ngValue]="10">10 items per page</option>
    <option [ngValue]="15">15 items per page</option>
    <option [ngValue]="20">20 items per page</option>
  </select>
  <div class="d-flex justify-content-center table-responsive" *ngIf="!IdeventData">
       <table class="table card-table table-striped ">
        <thead class="thead-light">
          <tr>
                
            <th>Id</th>
            <th>Status</th>    
            <th>Type</th>
            <th>Source</th>
            <th>Total Article</th> 
            <th>Total Succcess</th>
            <th>Total Error</th>
            <th>Rss Feed Link </th>

            
            
          </tr>
            
        </thead>
        <tbody>
          <tr *ngFor="let item of eventData">
            <td >{{item.eventId}}</td>
            <td >{{item.eventStatus}}</td> 
            <td>{{item.eventType}}</td>
            <td>{{item.rssFeed.dataSource.dataSourceName}}</td>
            <td>{{item.totalArticle || "-"}}</td>
            <td>{{item.totalSucccessfull || "-"}}</td>
            <td>{{item.totalError || "-"}}</td>
            <td><a href="{{item.rssFeed.rssFeedLink}}" target="_blank"><button class="btn btn-warning"  >
              Rss Feed Link
            </button></a></td>
          <!-- </td>
            <td>{{item.rssFeed.rssFeedLink}}</td> -->
          </tr>
          <tr >
                        
            
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="12">
              <div class="d-flex justify-content-center ">
                <ngb-pagination 
                [(page)]="page"
                [collectionSize]="collectionSize" 
                
                [pageSize]="pageSize" 
                (pageChange)="refreshData($event)"
                [maxSize]="3" 
                
                [rotate]="true" 
                [boundaryLinks]="true"
                >
                </ngb-pagination>
              
                
              </div>  
            </td>
          </tr>
        </tfoot>
      </table>    
  </div>
  <div class="d-flex justify-content-center table-responsive" *ngIf="IdeventData">
    <table class="table card-table table-striped ">
     <thead class="thead-light">
       <tr>
             
         <th>Id</th>
         <th>Status</th>    
         <th>Type</th>
         <th>Source</th>
         <th>Total Article</th> 
         <th>Total Succcess</th>
         <th>Total Error</th>
         <th>Rss Feed Link </th>

         
         
       </tr>
         
     </thead>
     <tbody>
       <tr >
         <td >{{IdeventData.eventId}}</td>
         <td >{{IdeventData.eventStatus}}</td> 
         <td>{{IdeventData.eventType}}</td>
         <td>{{IdeventData.rssFeed.dataSource.dataSourceName}}</td>
         <td>{{IdeventData.totalArticle || "-"}}</td>
         <td>{{IdeventData.totalSucccessfull || "-"}}</td>
         <td>{{IdeventData.totalError || "-"}}</td>
         <td><a href="{{IdeventData.rssFeed.rssFeedLink}}" target="_blank"><button class="btn btn-warning"  >
           Rss Feed Link
         </button></a></td>
       <!-- </td>
         <td>{{item.rssFeed.rssFeedLink}}</td> -->
       </tr>
       <tr >
                     
         
       </tr>
     </tbody>
   </table>    
</div>
</div>
