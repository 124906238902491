<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>
        </li>
    </ul>
  </nav>
  <span class="float-left">
    <a  floating="true" (click)="goback()" ><fa-icon [icon]="faBackspace" class="mt-3 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
<div class="container-fluid">
    <div class="d-flex justify-content-center">
        <h1>RSS Feeds</h1>
    </div>
    
</div>
<div class="container-fluid ">
    <div class="d-flex justify-content-center" >
        <input  width="50%" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search">
    </div>
    
    <button type="button" class="btn btn-success" id="complete-btn" (click)="verify()">Verify DataSource</button>
    <button class="btn btn-primary" id="create-btn" (click)="open(content)">Add New RSS</button>

    <!-- <a  href="{{this.new.individualUrl}}" target="_blank">{{this.new.individualUrl}}</a> -->
    <div class="d-flex justify-content-center table-responsive">
         <table class="table card-table   table-hover">
          <thead class="thead-light">
            <tr>
               <th>Feed ID</th>    
              <th>Feed Page URL</th>
              <th>Feed Link</th>
              <th>Category</th>    
              
              <th>Actions</th>
              
            </tr>
              
          </thead>
          <tbody>
            <tr *ngFor="let item of rss|filter:searchText">
              <td >{{item.rssFeedId}}</td>
              <td >{{item.feedPageURL}}</td> 
              <td>{{item.rssFeedLink}}</td>
              <td>{{item.category}}</td>
             
              
              <td>
                  <button class="btn btn-warning" (click)="onClick($event)"  id="{{item.rssFeedId}}" >Edit</button>
                
              
                </td>
            </tr>
            
          </tbody>
        </table>    
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Create RSS Feed</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form [formGroup]="createRssForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                    <label for="dataSourceId">Data Source Id</label>
                    <input type="text" formControlName="dataSourceId" [(ngModel)]="new" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.dataSourceId.errors }" />
                    <div *ngIf="submitted && fa.dataSourceId.errors" class="invalid-feedback">
                        <div *ngIf="fa.dataSourceId.errors.required">Data Source Id is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedFrequency">RSS Feed Frequency</label>
                    <input type="text" formControlName="rssFeedFrequency" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedFrequency.errors }" />
                    <div *ngIf="submitted && fa.rssFeedFrequency.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedFrequency.errors.required">Rss Feed Frequency is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedSelector"> Rss Feed Page Selector </label>
                    <input type="text" formControlName="rssFeedSelector"  class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedSelector.errors }" />
                    <div *ngIf="submitted && fa.rssFeedSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedSelector.errors.required"> Rss Feed Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="feedPageURL">RSS Feed Page URL</label>
                    <input type="text" formControlName="feedPageURL"  class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedPageURL.errors }" />
                    <div *ngIf="submitted && fa.feedPageURL.errors" class="invalid-feedback">
                        <div *ngIf="fa.feedPageURL.errors.required">Feed Page URL is required</div>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="feedURLChangable">RSS Feed URL Changable</label>
                    <input type="text" formControlName="feedURLChangable" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedURLChangable.errors }" />
                    <div *ngIf="submitted && fa.feedURLChangable.errors" class="invalid-feedback">
                        <div *ngIf="fa.feedURLChangable.errors.required">Feed URL Changable is required</div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label for="feedURLChangable">RSS Feed URL Changable</label>
                    <div >
                    
                    <div class=" form-check form-check-inline">
                        <input data-md-icheck  formControlName="feedURLChangable" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedURLChangable.errors }" class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true" > 
                        <label class="form-check-label" for="feedURLChangable">True</label>
                    </div>
                    
                  
                    <div class="form-check form-check-inline">
                          <input data-md-icheck   formControlName="feedURLChangable" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedURLChangable.errors }" class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false" >
                          <label class="form-check-label" for="feedURLChangable">False</label>
                    </div>
                    <div *ngIf="submitted && fa.feedURLChangable.errors" class="invalid-feedback">
                      <div *ngIf="fa.feedURLChangable.errors.required">RSS Feed URL Changable is required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedRootTag">RSS Feed Root Tag</label>
                    <input type="text" formControlName="rssFeedRootTag"  class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedRootTag.errors }" />
                    <div *ngIf="submitted && fa.rssFeedRootTag.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedRootTag.errors.required">Rss Feed Root Tag is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="category">Category</label>
                    <input type="text" formControlName="category" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.category.errors }" />
                    <div *ngIf="submitted && fa.category.errors" class="invalid-feedback">
                        <div *ngIf="fa.category.errors.required">Category is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedLink">RSS Feed Link</label>
                    <input type="text" formControlName="rssFeedLink" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedLink.errors }" />
                    <div *ngIf="submitted && fa.rssFeedLink.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedLink.errors.required">Rss Feed Link is required</div>
                    </div>
                </div>
           
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button [disabled]="loading" class="btn btn-success">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                </div>
                
                <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
            </form>
            </div>
        
            
      </ng-template>
</div>
