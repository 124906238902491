import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { userInfo } from 'os';


@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private auth:AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): 
  Observable<HttpEvent<any>> {
    
    const headersConfig = {
      Accept: "application/json",
   
   };   
   
    const val =this.auth.userValue
    const isLoggedIn =val && val.access_token
        if (request.url.indexOf("/oauth/token")>0) {
          console.log("AAAAAAA");
          
          headersConfig["Authorization"]='Basic MjM1ZGUyZGMzMWM3NTJlNjIxNWI3YWQ5ZTRhZTMzYTdmYThlMDlkNjc1MTc0NmM2OGUzZjFhMTVmOWZhMmVhNjo3MThhMTg2NmY0ODc2YzkxZTUxOGI3ZGUzZDM3YjM4ZjhlNmJhZGU0MTMzNTBmZDZlYjcyNmIyZWE3YjlmN2E4';
          
          headersConfig["Access-Control-Allow-Origin"]='*';
        } 
           
        if (isLoggedIn && request.url.indexOf('/v1.0/')>0) {
          console.log("BBBBBBBBBBBBBBBBBBBBBB");
          
          headersConfig["Authorization"]=`Bearer ${val.access_token}`;
          
          headersConfig["Access-Control-Allow-Origin"]='*';
        } 
  
  
  request = request.clone({
    setHeaders: headersConfig
  });
    console.log(request)
    return next.handle(request);
  }
}
