import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, Form } from '@angular/forms';
import{AuthService} from '../../services/auth.service'
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-rssfeed',
  templateUrl: './rssfeed.component.html',
  styleUrls: ['./rssfeed.component.css']
})
export class RssfeedComponent implements OnInit {
  faBackspace=faBackspace
  sub: any;
  new: any;
  page: { page: string; size: string; order: string; orderBy: string; rssFeedStatus: string; countryCode: string; };
  order: { page: number; size: string; order: string; orderBy: string; dataSourceId: any; };
  countries: any;
  language: any;
  rss: any;
  closeResult: string;
  createRssForm:FormGroup
  submitted: boolean;
  loading: boolean;
  complete: { dataSourceId: any; status: string; };
  searchText:any

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth :AuthService,
    private location:Location,
    private spinner:NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      
      console.log("runn",params)
      this.new=params['id']
      console.log(this.new);
  });
  this.order ={page : 0,size : '300',order: 'ASC',orderBy:'createdDate',dataSourceId : this.new}
  console.log(this.order)
  this.data.getRssFeed(this.order).subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.rss=data['data']['content']
        console.log('all',this.rss);
        
        
      }
     
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      let alertObj = {
            message: err.error.message,
            status: err.error.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
        
    }
  )
  this.data.getDataSourceById(this.new).subscribe(
    data=>{
      console.log(data)
      if(data.data.sampleArticleImageUrl==null){
        this.spinner.show()
          this.data.getOldSourceImage(this.new).subscribe(
            data=>{
              console.log(data)
              this.spinner.hide();
            },
            (err:HttpErrorResponse)=>{
              console.log(err)
              let alertObj = {
                message: err.error.message,
                status: err.error.infoType,
                autoDismiss: true,
                timeToDismiss: 5000
              };
              this.data.toastrShow(alertObj.message,alertObj.status)
            
            }
          )
        
        
      }
    }
  )
  this.data.getcountry().subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.countries=data['data']
      }
    }
  )
  this.data.getlang().subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.language=data['data']
      }
    }
  );
  this.createRssForm = this.formBuilder.group({
    dataSourceId: ['', Validators.required],
    rssFeedFrequency: ['', Validators.required],
    rssFeedSelector: ['', Validators.required],
    feedPageURL: ['', Validators.required],
    feedURLChangable: ['', Validators.required],
    rssFeedRootTag: ['', Validators.required],
    category: ['', Validators.required],
    rssFeedLink: ['', Validators.required],
    
    });

  }

  onClick(event){
   
    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let rssId = idAttr.nodeValue;
    
    console.log(rssId); 
    this.router.navigate(['/rssdetails',rssId])

  }
  get fa() { return this.createRssForm.controls; }
  onSubmitnew(){
    console.log(this.createRssForm.value);
    this.submitted =true;
      if(this.createRssForm.invalid) {
        return;
      
      }
      this.loading = true;
      console.log(this.createRssForm.value);
      this.data.createRssFeed(this.createRssForm.value).subscribe(
        data=>{
          this.loading=false
          if(data.statusCode==200){
            let alertObj = {
              message: data.message,
              status: data.infoType,
              autoDismiss: true,
              timeToDismiss: 2000
            };
            this.data.toastrShow(alertObj.message,alertObj.status)
            this.modalService.dismissAll();
            console.log(data)
            setTimeout(
              function(){ 
              location.reload(); 
              }, 3000);
          }
        },
        (err:HttpErrorResponse)=>{
          this.loading=false
          console.log(err)
          let alertObj = {
            message: err.error.message,
            status: err.error.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      )
  }
 verify(){
    
    this.router.navigate(['/extracted-data',this.new])
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // closeModal(content) {
  //   this.modalService.close(content);
  // }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  logout(){
    this.auth.logout()
  }
  goback(){
    this.location.back()
  }
}
