<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <button class="btn btn-danger" (click)="logout()">Logout</button>
      </li>
    </ul>
</nav>
<span class="float-left">
    <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
  <span class="float-right mt-2 mr-4 mb-2">
    <button class="btn btn-success" (click)="imglabel()">Label Image</button>
    <button class="btn btn-success" (click)="completeRss()">Mark Complete</button>
 
  </span>
<div class="container-fluid mt-5">

    
    <div class="card-columns">

    <div class="card mb-3" style="width: 20rem;height: 100%;" *ngFor="let item of extractedData">
        <img class="card-img-top" [src]="item.articleImage" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">{{item.articleTitle}}</h5>
          <p class="card-text"><small style="font-size: 0.9em;" class="text-muted">Author:{{item.articleAuthor}}</small></p>

          <p class="card-text"><small class="text-muted">{{item.articleDate}}</small></p>
        
          <p class="card-text ">{{(item.articleBody.length>100)?(item.articleBody|slice:0:300)+'...':(item.articleBody)}}</p>
          <a href="{{item.articleLink}}" target="_blank"  class="btn btn-primary">Read Full Article</a>
          
        </div>
      </div>
      
</div>
</div>

