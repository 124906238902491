import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, Form } from '@angular/forms';
import{AuthService} from '../../services/auth.service'
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-extracted-data',
  templateUrl: './extracted-data.component.html',
  styleUrls: ['./extracted-data.component.css']
})
export class ExtractedDataComponent implements OnInit {
  id: string;
  extractedData: any;
faBackspace=faBackspace
  complete: { dataSourceId: any; status: string; };
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth :AuthService,
    private location:Location,
    private spinner:NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.id=this.activatedRoute.snapshot.paramMap.get('id')
    console.log(this.id)
    this.data.getExtractedDatab(this.id).subscribe(
      data=>{
        this.spinner.hide()
        if(data.statusCode==200){
          console.log(data)
          
          this.extractedData=data['data']
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  completeRss(){
    this.complete={dataSourceId:this.id,status:'COMPLETED'}
    console.log(this.complete)
    this.spinner.show()
    this.data.completeRSS(this.complete).subscribe(
      data=>{
        if(data.statusCode==200){
          this.spinner.hide()
          console.log(data)
          let alertObj = {
            message: data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
          
        }
        
        
        

      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message: err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
logout(){
  this.auth.logout();
}

goback(){
  this.location.back();
}
imglabel(){
  this.spinner.show();
  this.router.navigate(['image-labelling',this.id])
}
}
