import { Injectable,Inject } from '@angular/core';
import { map } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders} from "@angular/common/http";
import { Shape } from '../models/shape';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http:HttpClient,
    private toastr: ToastrService,
    @Inject("API_URL") public API_URL: any
  ) { }

  getCountryFilter(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/country`,obj)
  }
  getcountry(){
    return this.http.get<any>(`${this.API_URL}/v1.0/country`)

  }
  createCountry(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/country/create`,obj)

  }
  getCountryByCode(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/country/code/${obj}`)

  }
  updateCountry(obj){
    return this.http.put<any>(`${this.API_URL}/v1.0/country/update`,obj)

  }
  getlang(){
    return this.http.get<any>(`${this.API_URL}/v1.0/language`)

  }
  createlang(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/language/create`,obj)

  }
  updatelang(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/language/update`,obj)

  }
  getlangByCode(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/language/code/${obj}`)
  }
  getDatePattern(){
    return this.http.get<any>(`${this.API_URL}/v1.0/datepattern/`)
  }
createDate(obj){
  return this.http.post<any>(`${this.API_URL}/v1.0/datepattern/create`,obj)
}
  getDateById(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/datepattern/${obj}`)

  }
  updateDatePattern(obj){
    return this.http.put<any>(`${this.API_URL}/v1.0/datepattern/update`,obj)

  }
  getdatasources(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/datasource`,obj).pipe(
      map(
        res=>{
          return res;
        })
    );
  }
  createDataSource(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/datasource/create`,obj).pipe(
      map(
        res=>{
          return res;
        })
    );
  }
  updateDataSource(obj){
    return this.http.put<any>(`${this.API_URL}/v1.0/datasource/update`,obj).pipe(
      map(
        res=>{
          return res;
        }
        
      )
    )
  }
  getDataSourceById(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/datasource/id/${obj}`)
  }
  getSourceByName(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/datasource/name/${obj}`)

  }
  createRssFeed(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/rssfeed/create`,obj)
  }
  updateRssFeed(obj){
    return this.http.put<any>(`${this.API_URL}/v1.0/rssfeed/update`,obj)
  }
  getRssFeed(order:Object){
    return this.http.post<any>(`${this.API_URL}/v1.0/rssfeed`,order)

  }
  getRssFeedByID(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/rssfeed/id/${obj}`)
  }
  completeRSS(obj){
    return this.http.put<any>(`${this.API_URL}/v1.0/datasource/mark/complete`,obj)
  }
  getExtractedDatab(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/scrape-article/id/${obj}`)
  }
  getImageUrl(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/datasource/get-screenshot-url/id/${obj}`)

  }
  getOldSourceImage(obj){
    return this.http.put<any>(`${this.API_URL}/v1.0/datasource/add-screenshot?id=${obj}`,null)

  }
  getImageSize(obj){
    return this.http.get<any>(`${this.API_URL}/v1.0/datasource/labelled-image-info?id=${obj}`)

  }
  updateLabels(id,body){
    return this.http.put<any>(`${this.API_URL}/v1.0/datasource/save-labelled-data?id=${id}`,body)

  }
  labelledimage(id){
    return this.http.get<any>(`${this.API_URL}/v1.0/datasource/labelled-image-info?id=${id}`)

  }
  getEventBYFilter(obj){
    return this.http.post<any>(`${this.API_URL}/v1.0/rss/event`,obj)

  }

  getEventById(id){
    return this.http.get<any>(`${this.API_URL}/v1.0/rss/event/id/${id}`)

  }


  toastrShow(message: any, type: any) {
    this.toastr.clear();
    switch (type) {
      case "INFO": {
        this.toastr.info("", message, { timeOut: 5000 });
        break;
      }
      case "ERROR": {
        this.toastr.error("", message, { timeOut: 5000 });
        break;
      }
      case "SUCCESS": {
        this.toastr.success("", message, { timeOut: 5000 });
        break;
      }
      default: {
        this.toastr.warning("", message, { timeOut: 5000 });
        break;
      }
    }
  }

}
