import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import{AuthService} from '../../services/auth.service';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-rssdetails',
  templateUrl: './rssdetails.component.html',
  styleUrls: ['./rssdetails.component.css']
})
export class RssdetailsComponent implements OnInit {
  faBackspace=faBackspace
  sub: any;
  new: number;
  updateRssForm:FormGroup
  rssdetails: any;
  submitted: boolean;
  loading: boolean;
  closeResult: string;
  dataSource: any;
  dataId: any;
  dataid: any;
  str: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private location: Location,
    private auth:AuthService
  ) { }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      
      console.log("runn",params)
      this.new=params['id']
      console.log(this.new);
    });
    this.data.getRssFeedByID(this.new).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data);
          this.rssdetails=data.data
          this.dataSource=this.rssdetails.dataSource
          this.dataid=JSON.stringify(this.dataSource.dataSourceId)
          this.str=JSON.stringify(this.rssdetails)
          console.log(this.str);
          
          console.log(this.dataSource);
          console.log(this.dataid);
          console.log("updated",this.rssdetails);
          
          
        }
      }
    );
    this.updateRssForm = new FormGroup({
      rssFeedId: new FormControl('', Validators.required),
      dataSourceId:new FormControl( '', Validators.required),
      rssFeedLink:new FormControl( '', Validators.required),
      category:new FormControl( '', Validators.required),
      rssFeedRootTag:new FormControl( '', Validators.required),
      status:new FormControl('', Validators.required),
      feedURLChangable:new FormControl( '', Validators.required),
      feedPageURL:new FormControl( '', Validators.required),
      rssFeedSelector:new FormControl( '', Validators.required),
      rssFeedFrequency:new FormControl( '', Validators.required),
      
      
      });
      // this.updateRssForm.get('rssFeedId').setValue(this.rssdetails.rssFeedId);
      // this.updateRssForm.get('dataSourceId').setValue(this.dataId);
      
      // (<FormGroup>this.updateRssForm)
      //       rssFeedId.setValue(, {onlySelf: true});
    
    // this.updateRssForm = this.formBuilder.group({
    //   rssFeedId: [this.new, Validators.required],
    //   dataSourceId: [this.dataid, Validators.required],
    //   rssFeedLink: ['', Validators.required],
    //   category: ['', Validators.required],
    //   rssFeedRootTag: ['', Validators.required],
    //   status: [, Validators.required],
    //   feedURLChangable: ['', Validators.required],
    //   feedPageURL: ['', Validators.required],
    //   rssFeedSelector: ['', Validators.required],
    //   rssFeedFrequency: ['', Validators.required],
      
      
    //   });
    
  }
  get fa() { return this.updateRssForm.controls; }
  onSubmitnew(){
    
  this.submitted = true;
    if(this.updateRssForm.invalid) {
      return;
      
  }
  this.loading = true;
  console.log(this.updateRssForm.value);
  
  this.data.updateRssFeed(this.updateRssForm.value).subscribe(
    data=>{
      this.loading = false;
      if(data.statusCode==200){
        let alertObj = {
          message: data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
        this.modalService.dismissAll();
        console.log(data)
      }
    },
    (err:HttpErrorResponse)=>{
      this.loading=false
      console.log(err)
      let alertObj = {
        message: err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
    }
  )

  
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // closeModal(content) {
  //   this.modalService.close(content);
  // }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  goback(){
    this.location.back()
  }
  logout(){
    this.auth.logout()
  }
}
