import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      // console.log("guard",localStorage.getItem('user'))
      const currentUser = this.auth.userValue;
      const roles=this.auth.roles
      // console.log(roles.slug)
      console.log(next.data.role)
        if (currentUser ) {
          if( next.data.role && next.data.role.indexOf(roles.slug)===-1  ){
            this.router.navigate['/']
            return false
            
            
          }
          console.log("new",currentUser)
            // logged in so return true
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    
  }
  
}
