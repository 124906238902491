<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>
        </li>
      </ul>
    <!-- Links -->
    <!-- <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#">Link 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 3</a>
      </li>
    </ul> -->
</nav>
<span class="float-left">
    <a  floating="true" (click)="goback()" ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
  <div class="container-fluid">
      <div class="d-flex justify-content-center">
          <h1>RSS Details</h1>
        </div>
    </div>
<div class="container-fluid">
    <div class="d-flex justify-content-center">
        <div class=" card">
            <div class="d-flex justify-content-center">
                
                <button class="btn btn-primary" (click)="open(content)">Update </button>
            </div>  
        <div class="card-body">
            <table class="table card-table   table-hover">
                <thead class="thead-light">
                  <tr>
                     <th>Property</th>    
                    <th>Value</th>
                    
                    
                  </tr>
                    
                </thead>
                <tbody>
                    <tr>
                        <td >RSS Feed Id</td>
                        <td >{{rssdetails?.rssFeedId}}</td>
                    </tr>
                    <tr>
                        <td >Data Source Id</td>
                        <td >{{dataSource?.dataSourceId}}</td>
                    </tr>
                    <tr>
                        <td >RSS Feed Link</td>
                        <td >{{rssdetails?.rssFeedLink}}</td>
                    </tr>
                    <tr>
                        <td >Category</td>
                        <td >{{rssdetails?.category}}</td>
                    </tr>
                    <tr>
                        <td >RSS Feed Root Tag</td>
                        <td >{{rssdetails?.rssFeedRootTag}}</td>
                    </tr>
                    <tr>
                        <td >RSS Feed URL Changable</td>
                        <td >{{rssdetails?.feedURLChangable}}</td>
                    </tr>
                    <tr>
                        <td >RSS Feed Page URL</td>
                        <td >{{rssdetails?.feedPageURL}}</td>
                    </tr>
                    <tr>
                        <td >RSS Feed Page Selector</td>
                        <td >{{rssdetails?.rssFeedSelector}}</td>
                    </tr>
                    <tr>
                        <td >RSS Feed Frequency</td>
                        <td >{{rssdetails?.rssFeedFrequency}}</td>
                    </tr>
                    <tr>
                        <td >Status</td>
                        <td >{{rssdetails?.status}}</td>
                    </tr>
                    
                    <tr>
                        <td >Last Scheduled</td>
                        <td >{{rssdetails?.lastScheduled}}</td>
                    </tr>
                    <tr>
                        <td >Created Date</td>
                        <td >{{rssdetails?.createdDate}}</td>
                    </tr>
                    <tr>
                        <td >Last Modified Date</td>
                        <td >{{rssdetails?.lastModifiedDate}}</td>
                    </tr>
                
                </tbody>
            </table>
        

         
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Update Data Source</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form [formGroup]="updateRssForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                    
                    <label for="rssFeedId">Rss Feed Id :{{rssdetails.rssFeedId}}</label>
                    
                    <input  type="text" formControlName="rssFeedId" [(ngModel)]="rssdetails.rssFeedId"  class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedId.errors }" />
                    <div *ngIf="submitted && fa.rssFeedId.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedId.errors.required">Rss Feed Id is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="dataSourceId">Data Source Id :{{dataid}}</label>
                    <input type="text" formControlName="dataSourceId" [(ngModel)]="dataSource.dataSourceId" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.dataSourceId.errors }" />
                    <div *ngIf="submitted && fa.dataSourceId.errors" class="invalid-feedback">
                        <div *ngIf="fa.dataSourceId.errors.required">Data Source Id is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedLink">Rss Feed Link</label>
                    <input type="text" formControlName="rssFeedLink" [(ngModel)]="rssdetails.rssFeedLink" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedLink.errors }" />
                    <div *ngIf="submitted && fa.rssFeedLink.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedLink.errors.required">Rss Feed Link is required</div>
                    </div>
                </div>
                
                <div class="form-group">
                    <label for="category">Category</label>
                    <input type="text" formControlName="category" [(ngModel)]="rssdetails.category" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.category.errors }" />
                    <div *ngIf="submitted && fa.category.errors" class="invalid-feedback">
                        <div *ngIf="fa.category.errors.required">Category is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedRootTag">Rss Feed Root Tag</label>
                    <input type="text" formControlName="rssFeedRootTag" [(ngModel)]="rssdetails.rssFeedRootTag" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedRootTag.errors }" />
                    <div *ngIf="submitted && fa.rssFeedRootTag.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedRootTag.errors.required">Rss Feed Root Tag is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="status">Status</label>
                    <input type="text" formControlName="status" class="form-control" [(ngModel)]="rssdetails.status" [ngClass]="{ 'is-invalid': submitted && fa.status.errors }" />
                    <div *ngIf="submitted && fa.status.errors" class="invalid-feedback">
                        <div *ngIf="fa.status.errors.required">Status is required</div>
                    </div>
                </div>
                
                <!-- <div class="form-group">
                    <label for="feedURLChangable">RSS Feed URL Changable</label>
                    <input type="text" formControlName="feedURLChangable" [(ngModel)]="rssdetails.feedURLChangable" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedURLChangable.errors }" />
                    <div *ngIf="submitted && fa.feedURLChangable.errors" class="invalid-feedback">
                        <div *ngIf="fa.feedURLChangable.errors.required">RSS Feed URL Changable is required</div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label for="feedURLChangable">RSS Feed URL Changable</label>
                    <div >
                    
                    <div class=" form-check form-check-inline">
                        <input data-md-icheck [(ngModel)]="rssdetails.feedURLChangable" formControlName="feedURLChangable" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedURLChangable.errors }" class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true" > 
                        <label class="form-check-label" for="feedURLChangable">True</label>
                    </div>
                    
                  
                    <div class="form-check form-check-inline">
                          <input data-md-icheck [(ngModel)]="rssdetails.feedURLChangable"  formControlName="feedURLChangable" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.feedURLChangable.errors }" class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false" >
                          <label class="form-check-label" for="feedURLChangable">False</label>
                    </div>
                    <div *ngIf="submitted && fa.feedURLChangable.errors" class="invalid-feedback">
                      <div *ngIf="fa.feedURLChangable.errors.required">RSS Feed URL Changable is required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                    <label for="feedPageURL">RSS Feed Page URL</label>
                    <input type="text" formControlName="feedPageURL" class="form-control" [(ngModel)]="rssdetails.feedPageURL" [ngClass]="{ 'is-invalid': submitted && fa.feedPageURL.errors }" />
                    <div *ngIf="submitted && fa.feedPageURL.errors" class="invalid-feedback">
                        <div *ngIf="fa.feedPageURL.errors.required">RSSFeed Page URL is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedSelector"> Rss Feed Page Selector </label>
                    <input type="text" formControlName="rssFeedSelector" [(ngModel)]="rssdetails.rssFeedSelector" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedSelector.errors }" />
                    <div *ngIf="submitted && fa.rssFeedSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedSelector.errors.required"> Rss Feed Page Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedFrequency">Rss Feed Frequency</label>
                    <input type="text" formControlName="rssFeedFrequency" [(ngModel)]="rssdetails.rssFeedFrequency" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedFrequency.errors }" />
                    <div *ngIf="submitted && fa.rssFeedFrequency.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedFrequency.errors.required">Rss Feed Frequency is required</div>
                    </div>
                </div>
                
                 
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button [disabled]="loading" class="btn btn-success">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                </div>
                
                <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
            </form>
            </div>
        
            
      </ng-template>
</div>
    